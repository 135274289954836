import { AfterViewInit, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { EventGroupModel } from '../event.model';
import { DraggableListViewConfigModel } from '../../shared/draggable-list-view/draggable-list-view-model';
import { getState } from '../../store/models/app.state';
import { filter, take, tap } from 'rxjs/operators';
import { ProjectEventModel } from '../project-event.model';
import { ListViewColumnModel } from '../../shared/list-view/list-view-column.model';
import { EventActions } from '../enums/event-actions.enum';
import {
  changeCurrentProjectEvent,
  createProjectEvent,
  dispatchedCreateUpdateEvent,
  pendingCreateUpdateEvent,
  resetCurrentProjectEvent,
  successCreateUpdateEvent,
  updateProjectEvent,
} from '../store/event.actions';
import { CurrentEventManage } from '../store/event.state';
import { EventsEditorTabEnum } from '../events/events.component';
import { EntityType } from '../../core/models/entity-type-enum';
import { ProjectService } from '../../project/project.service';
import { Statuses } from '../../shared/models/statuses.enum';
import { PermissionsEnum } from '../../permission/permissions.enum';
import { EventsListBaseComponent } from '../events-list-base/events-list-base.component';

@Component({
  selector: 'phar-adhoc-events-list',
  templateUrl: 'adhoc-events-list.component.html',
  styleUrls: ['adhoc-events-list.component.scss'],
})
export class AdhocEventsListComponent extends EventsListBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('elementTypeTemplate', { static: true }) elementTypeTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('statusChangeTemplate', { static: true }) statusChangeTemplate: TemplateRef<any>;
  @ViewChild('toggleAllTemplate', { static: true }) toggleAllTemplate: TemplateRef<any>;
  @ViewChild('commentsTemplate', { static: true }) commentsTemplate: TemplateRef<any>;
  projectService: ProjectService = inject(ProjectService);
  isEditorOpened = false;
  activeEditorTab: EventsEditorTabEnum = EventsEditorTabEnum.Properties;

  readonly config: DraggableListViewConfigModel = {
    columns: [
      {
        field: 'id',
        size: '0',
        title: 'ID',
        show: false,
        hasTemplate: false,
        template: null,
      },
      {
        field: 'expand',
        size: '40px',
        title: '',
        show: true,
        hasTemplate: false,
        template: null,
      },
      {
        field: 'eventName',
        title: 'Event Name',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: false,
        selectable: true,
        template: null,
      },
      {
        field: 'elementType',
        title: 'Event Type',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'userIdUpdated', // @TODO change this to status column when its done
        title: 'Status',
        size: '120px',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'changeStatus',
        title: 'Change status',
        size: '100px',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
        hasHeaderCellTemplate: true,
      },
      {
        field: 'projectEventGuid', // field is used only for placeholder
        title: 'Comments',
        size: '80px',
        show: true,
        hasTemplate: true,
        template: null,
      },
      {
        field: 'userIdCreated', // field is used only for placeholder
        title: 'Actions',
        size: '50px',
        // Add as many pixels as left margin added for nested columns (2rem).
        // sizeNested: '86px',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
    ],
    actions: [],
    customClasses: [],
  };
  protected readonly EventsEditorTabEnum = EventsEditorTabEnum;
  private eventState$: Observable<CurrentEventManage>;

  constructor() {
    super('adhoc');
  }

  ngOnInit() {
    super.ngOnInit();

    this.eventState$ = this.store.select(state => state.event.current);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    const templates = {
      elementType: this.elementTypeTemplate,
      userIdCreated: this.actionsTemplate,
      userIdUpdated: this.statusTemplate,
      changeStatus: this.statusChangeTemplate,
      'changeStatus@header': this.toggleAllTemplate,
      projectEventGuid: this.commentsTemplate,
    };

    this.config.columns = this.utilsService.setColumnTemplate<ListViewColumnModel>(
      this.config.columns,
      templates,
      'field',
    );
    if (this.selectedEventId) {
      this.scrollToElement(this.selectedEventId);
    }
  }

  addNewEvent() {
    if (this.editLocked()) {
      return;
    }
    this.selectedEventId = null;
    this.store.dispatch(resetCurrentProjectEvent());
    this.openEditor();
  }

  handleSave() {
    let projectEvent: ProjectEventModel = {
      ...getState(this.store).event.current.projectEvent,
    };

    this.store.dispatch(pendingCreateUpdateEvent({ pending: true }));
    this.store.dispatch(dispatchedCreateUpdateEvent({ dispatched: true }));
    if (!projectEvent.id) {
      projectEvent = {
        ...projectEvent,
        projectId: this.project().id,
        status: this.prevStatus(),
        event: {
          ...projectEvent.event,
          start: 0,
        },
      };
      this.store.dispatch(createProjectEvent({ projectEvent }));
    } else {
      if (this.projectStatus() === Statuses.RevisionInProgress && projectEvent?.status !== Statuses.New) {
        projectEvent.status = Statuses.PendingUpdate;
      }
      this.store.dispatch(updateProjectEvent({ projectEvent }));
    }

    this.eventState$
      .pipe(
        filter(({ dispatched, success }) => dispatched && success),
        tap(() => {
          this.store.dispatch(pendingCreateUpdateEvent({ pending: false }));
          this.store.dispatch(successCreateUpdateEvent({ success: false }));
          this.closeEditor();
        }),
        take(1),
      )
      .subscribe();
  }

  openEditor() {
    this.isEditorOpened = true;
  }

  closeEditor() {
    this.isEditorOpened = false;
    this.selectedEventId = null;
    this.activeEditorTab = EventsEditorTabEnum.Properties;
    this.store.dispatch(resetCurrentProjectEvent());
    this.eventsContainer.nativeElement.style.removeProperty('--editor-width');
  }

  actionHandler($event: { eventName: EventActions; dataItem: EventGroupModel }): void {
    const { eventName, dataItem } = $event;
    switch (eventName) {
      case EventActions.Edit:
        this.handleEdit(dataItem.id);
        break;
      case EventActions.Delete:
        this.handleDeleteEvent(dataItem);
        break;
      case EventActions.Duplicate:
        this.handleDuplicateEvent(dataItem);
        break;
      case EventActions.Comments:
        this.openCommentsDialog(dataItem);
        break;
      default:
        break;
    }
  }

  handleEdit(id: number): void {
    const projectEvent = this.eventToProjectEventMap()[id];
    // approved elements are not allowed for editing
    if (
      this.editLocked() ||
      (this.projectHasBeenRejected() && projectEvent.status === Statuses.Approved) ||
      projectEvent.status === this.nextStatus()
    ) {
      return;
    }

    this.selectedEventId = id;
    this.store.dispatch(changeCurrentProjectEvent({ eventId: id }));
    this.openEditor();
  }

  scrollToElement(elementId: number, prefix = 'item-'): void {
    // try {
    //   this.utilsService.waitForElementToExist(`#${prefix}${elementId}`).then((element: HTMLElement) => {
    //     (element as HTMLElement).scrollIntoView({ behavior: 'smooth' });
    //   });
    // } catch (e) {
    //   if (!environment.production) {
    //     console.warn(e);
    //   }
    // }
  }

  closeEditorIfEventOpened(eventId: number): void {
    if (eventId === this.currentEvent().id) {
      this.closeEditor();
    }
  }

  protected readonly EntityType = EntityType;
  protected readonly EventActions = EventActions;
  protected readonly Statuses = Statuses;
  protected readonly PermissionsEnum = PermissionsEnum;
}
