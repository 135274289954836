import {
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Injector,
  input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { cloneDeep, orderBy } from 'lodash-es';
import { select, Store } from '@ngrx/store';
import { AppState, getState } from '../../store/models/app.state';
import {
  checkProjectHasBeenRejected,
  selectCurrentProjectManageProject,
  selectCurrentProjectStatusStatus,
} from '../../project/store/project.actions';
import {
  deleteProjectEvent,
  loadEventNotificationsListByEventId,
  loadProjectEventListByProjectId,
  loadProjectEventsCommentsState,
  resetCurrentProjectEvent,
  resetEventNotificationsList,
  resetProjectEventList,
  softDeleteProjectEvent,
  updateProjectEvent,
  updateProjectEvents,
} from '../store/event.actions';
import {
  selectCurrentEventProjectEvent,
  selectEventsCommentsState,
  selectListEventNotificationsState,
} from '../store/event.state';
import { StudyEntityActionStateService } from '../../shared/services/study-entity-action-state.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { UtilsService } from '../../core/utils.service';
import { EventGroupModel, EventModel } from '../event.model';
import { EventsEditorTabEnum } from '../events/events.component';
import { DraggableListViewConfigModel } from '../../shared/draggable-list-view/draggable-list-view-model';
import { PermissionsEnum } from '../../permission/permissions.enum';
import { ProjectModel } from '../../project/project.model';
import { ProjectEventModel } from '../project-event.model';
import { EventCommentDialogComponent } from '../event-comments/event-comment.dialog.component';
import { ADHOC_EVENTS_TYPES } from '../../core/config/app.constants';
import { COMMENTS_DIALOG_CONFIG } from '../../shared/entity-comments/entity-comment-base/base-comments-component.config';
import { BaseComponent } from '../../shared/base.class';
import { ListViewColumnModel } from '../../shared/list-view/list-view-column.model';
import { ResizingEvent } from '../../shared/models/resizing-event.interface';
import { ICommentsState } from '../../shared/models/comments-state.interface';
import { Statuses } from '../../shared/models/statuses.enum';
import { ElementTypeEnum } from '../enums/element-type.enum';

@Component({
  selector: 'phar-events-list-base',
  templateUrl: '',
  standalone: true,
})
export class EventsListBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  protected permissionsService: NgxPermissionsService = inject(NgxPermissionsService);
  protected utilsService = inject(UtilsService);
  protected store: Store<AppState> = inject(Store);
  protected injector: Injector = inject(Injector);
  protected confirmationService = inject(PharConfirmDialogService);
  protected dialog = inject(MatDialog);
  protected studyEntityActionStateService = inject(StudyEntityActionStateService);

  @ViewChild('eventsContainer', { static: true }) eventsContainer: ElementRef;
  editLocked = input(false);
  @Output() duplicateEvent: EventEmitter<EventModel> = new EventEmitter<EventModel>();

  declare config: DraggableListViewConfigModel;
  baselineEventId: number = null;
  activeEditorTab: EventsEditorTabEnum = EventsEditorTabEnum.Properties;
  selectedEventId: number;
  selectedEvent$: Observable<EventModel>;
  numberOfEventNotifications$: Observable<number>;
  currentEvent = toSignal(this.store.select(selectCurrentEventProjectEvent), { injector: this.injector });
  project: Signal<ProjectModel> = toSignal(this.store.select(selectCurrentProjectManageProject), {
    injector: this.injector,
  });
  projectHasBeenRejected = toSignal(this.store.select(checkProjectHasBeenRejected), {
    injector: this.injector,
  });
  projectStatus = toSignal(this.store.select(selectCurrentProjectStatusStatus), {
    injector: this.injector,
  });
  nextStatus = computed(() => {
    return this.utilsService.getNextStatus(this.project());
  });
  prevStatus = computed(() => {
    if (this.projectHasBeenRejected()) {
      return Statuses.PendingRevision;
    }
    return this.utilsService.getNextStatus(this.project(), true);
  });
  projectEvents = toSignal(
    this.store.select(state => state.event.listProjectEvents),
    { injector: this.injector },
  );
  eventToProjectEventMap = computed(() => {
    const eventToProjectEventMapping = {};
    this.projectEvents().forEach(projectEvent => {
      // its needed because we are using projectEvent.id for comments,but in the actions template we are receiving event.id
      eventToProjectEventMapping[projectEvent.eventId] = projectEvent;
    });
    return eventToProjectEventMapping;
  });
  eventsList = toSignal(
    this.store
      .select(state => state.event.listProjectEvents)
      .pipe(
        map(projectEventList => {
          return projectEventList
            .filter((projectEvent: ProjectEventModel) => projectEvent.event !== null)
            .map((projectEvent: ProjectEventModel) => {
              if (this.eventsType === 'scheduled' && projectEvent.isBaseline) {
                this.baselineEventId = projectEvent.eventId;
              }

              return { ...projectEvent.event };
            });
        }),
        map((events: EventModel[]) =>
          this.eventsType === 'adhoc'
            ? events.filter((event: EventModel) => ADHOC_EVENTS_TYPES.includes(event.elementType))
            : events.filter((event: EventModel) => !ADHOC_EVENTS_TYPES.includes(event.elementType)),
        ),
        map((events: EventModel[]) => {
          return this.eventsType === 'adhoc' ? orderBy(events, ['id'], 'asc') : events;
        }),
      ),
    { injector: this.injector },
  );
  projectEventsCommentsCounters = toSignal(this.store.select(selectEventsCommentsState), { injector: this.injector });
  eventsCommentsState = computed(() => {
    const refinedCommentsStates = {};

    this.eventsList().forEach(event => {
      const projectEvent = this.eventToProjectEventMap()[event.id];
      if (!this.projectEventsCommentsCounters()[projectEvent.id]) {
        return;
      }
      refinedCommentsStates[projectEvent.id] = this.projectEventsCommentsCounters()[projectEvent.id];
    });
    return refinedCommentsStates;
  });
  allCommentsResolved = computed(() => {
    if (!Object.values(this.eventsCommentsState()).length) {
      return true;
    }
    return Object.values(this.eventsCommentsState()).every((comments: ICommentsState) => comments.isAllResolved);
  });
  isConfirmAllDisabled = computed(() => {
    return this.studyEntityActionStateService.isBulkStatusChangeLocked(
      this.eventsList()
        .filter(item => item.elementType !== ElementTypeEnum.Baseline)
        .map(item => {
          return this.eventToProjectEventMap()[item.id].status;
        }),
      this.projectStatus(),
      this.nextStatus(),
    );
  });
  isConfirmAllChecked = computed(() => {
    return this.studyEntityActionStateService.isBulkStatusChangeChecked(
      this.eventsList()
        .filter(item => item.elementType !== ElementTypeEnum.Baseline)
        .map(item => {
          return this.eventToProjectEventMap()[item.id].status;
        }),
      this.nextStatus(),
    );
  });

  protected rightBarResizingEvent: ResizingEvent = {
    isResizing: false,
    startingCursorX: 0,
    startingWidth: 0,
  };
  private readonly rightBarMinWidth = 270;
  private readonly rightBarMaxWidth = window.innerWidth / 2;

  constructor(private readonly eventsType: 'adhoc' | 'scheduled') {
    super();
  }

  ngOnInit(): void {
    this.selectedEventId = getState(this.store).event.current.projectEvent.event.id;

    this.store
      .pipe(
        select(state => state.project.current.project.id),
        filter(id => !!id),
        takeUntil(this.destroy$),
      )
      .subscribe(id => {
        this.store.dispatch(loadProjectEventListByProjectId({ id: id }));
      });

    this.selectedEvent$ = this.store.select(selectCurrentEventProjectEvent);
    this.numberOfEventNotifications$ = this.store
      .select(selectListEventNotificationsState)
      .pipe(map(eventNotifications => eventNotifications.length));

    this.loadEventNotificationsOnSelectEvent();
    this.handleChangeStatusColumnVisibility();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(resetProjectEventList());
    this.store.dispatch(resetCurrentProjectEvent());
  }

  setActiveEditorTab(tab: EventsEditorTabEnum): void {
    this.activeEditorTab = tab;
  }

  startRightBarResizing(event: MouseEvent): void {
    this.rightBarResizingEvent = {
      isResizing: true,
      startingCursorX: event.clientX,
      startingWidth: this.getRightBarWidth(),
    };
  }

  handleChangeStatus(element: EventModel, el: MatSlideToggle): void {
    const projectEvent: ProjectEventModel = this.eventToProjectEventMap()[element.id];
    if (!el.checked || !projectEvent) {
      el.checked = !el.checked;
      return;
    }

    // const itemMeta = this.adHocEventsCommentsState()[projectEvent.id];
    // if (itemMeta && !itemMeta.isAllResolved) {
    //   this.confirmationService
    //     .openConfirmDialog('All comments should be resolved', 'Warning', 'Ok', '')
    //     .pipe(take(1))
    //     .subscribe();
    //   el.checked = false;
    //
    //   return;
    // }
    if (projectEvent.status === Statuses.PendingDeletion) {
      this.confirmationService
        .openConfirmDialog('Do you want to remove ' + projectEvent.event.eventName + '?')
        .pipe(
          take(1),
          filter(isConfirmed => isConfirmed),
        )
        .subscribe(() => {
          this.store.dispatch(softDeleteProjectEvent({ id: projectEvent.id }));
        });
    } else {
      this.store.dispatch(updateProjectEvent({ projectEvent: { ...projectEvent, status: this.nextStatus() } }));
    }

    this.closeEditorIfEventOpened(projectEvent.event.id);
  }

  handleBulkStatusChange(el: MatSlideToggle): void {
    if (!el.checked) {
      el.checked = true;
      return;
    }
    // if (!this.allCommentsResolved()) {
    //   this.confirmationService
    //     .openConfirmDialog('All events comments should be resolved', 'Warning', 'Ok', '')
    //     .pipe(take(1))
    //     .subscribe();
    //   el.checked = false;
    //
    //   return;
    // }

    const projectEvents: ProjectEventModel[] = this.eventsList()
      .filter(el => el.elementType !== ElementTypeEnum.Baseline)
      .map(projectEvent => this.eventToProjectEventMap()[projectEvent.id])
      .filter(
        ({ status }) => !this.studyEntityActionStateService.isEntityChangeStatusChecked(status, this.nextStatus()),
      );

    const message = `You are about to move <b>${projectEvents.length}</b> records in status <b>
      ${this.utilsService.getFormattedStatus(this.nextStatus())}</b>. Are you sure?`;
    this.confirmationService
      .openConfirmDialog(message, 'Please confirm', 'Ok', 'Cancel', true, 300, true)
      .pipe(
        take(1),
        map(result => !!result),
        tap(result => {
          if (!result) {
            el.checked = false;
          }
        }),
        filter(canChangeStatuses => canChangeStatuses),
      )
      .subscribe({
        next: () => {
          //TODO: delete pending deletion events. Confirm button is disabled if there are pending deletion now
          const updatedProjectEvents = cloneDeep(projectEvents).map(projectEvent => ({
            ...projectEvent,
            status: this.nextStatus(),
          }));
          this.store.dispatch(updateProjectEvents({ projectEvents: updatedProjectEvents }));
        },
      });
  }

  closeEditorIfEventOpened(id: number): void {}

  columnsSelectionChange(columns: ListViewColumnModel[]): void {
    this.config.columns = columns;
  }

  openCommentsDialog(event: EventModel): void {
    const projectEvent = this.projectEvents().find(item => item.eventId === event.id);
    if (!projectEvent) {
      return;
    }
    this.dialog
      .open(EventCommentDialogComponent, {
        data: {
          projectEventId: projectEvent.id,
        },
        ...COMMENTS_DIALOG_CONFIG,
      })
      .afterClosed()
      .pipe(filter(shouldRefreshComments => shouldRefreshComments))
      .subscribe({
        next: () => {
          this.store.dispatch(loadProjectEventsCommentsState({ projectId: this.project().id }));
          // if (projectEvent.status === this.nextStatus()) {
          //   this.store.dispatch(updateProjectEvent({ projectEvent: { ...projectEvent, status: this.prevStatus() } }));
          // }
        },
      });
  }

  protected handleDeleteEvent(event: EventGroupModel): void {
    this.confirmationService
      .openConfirmDialog('Do you want to remove ' + event.eventName + '?')
      .pipe(
        filter(result => !!result),
        take(1),
      )
      .subscribe(() => {
        const projectEvent = this.eventToProjectEventMap()[event.id];
        // check if the item that you are about to delete is selected in the editor;
        this.closeEditorIfEventOpened(projectEvent.event.id);

        if (this.projectStatus() === Statuses.RevisionInProgress && projectEvent.status === Statuses.Active) {
          const updatedEvent = {
            ...projectEvent,
            status: Statuses.PendingDeletion,
          };

          this.store.dispatch(updateProjectEvent({ projectEvent: updatedEvent }));
          return;
        }

        this.store.dispatch(deleteProjectEvent({ id: projectEvent.id }));
      });
  }

  protected handleDuplicateEvent(event: EventGroupModel): void {
    this.duplicateEvent.emit(event);
  }

  @HostListener('window:mousemove', ['$event'])
  private updateSidebarWidth(event: MouseEvent) {
    if (!this.rightBarResizingEvent.isResizing) {
      return;
    }

    const cursorDeltaX = this.rightBarResizingEvent.startingCursorX - event.clientX;
    const newWidth = this.rightBarResizingEvent.startingWidth + cursorDeltaX;

    this.setRightBarWidth(newWidth);
  }

  @HostListener('window:mouseup')
  private stopSidebarResizing() {
    this.rightBarResizingEvent.isResizing = false;
  }

  private loadEventNotificationsOnSelectEvent(): void {
    this.selectedEvent$
      .pipe(
        map(selectedEvent => selectedEvent.id),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(selectedEventId => {
        this.setActiveEditorTab(EventsEditorTabEnum.Properties);
        if (selectedEventId) {
          this.store.dispatch(loadEventNotificationsListByEventId({ id: selectedEventId }));
        } else {
          this.store.dispatch(resetEventNotificationsList());
        }
      });
  }

  private handleChangeStatusColumnVisibility(): void {
    const changeStatusColumnIndex = this.config.columns.findIndex(c => c.field === 'changeStatus');
    if (changeStatusColumnIndex < 0) {
      return;
    }
    const showChangeStatusColumn = computed(() => {
      switch (this.nextStatus()) {
        case Statuses.ReadyForReview:
          return !!this.permissionsService.getPermission(PermissionsEnum.ChangeStatusEventsReadyForReview);
        case Statuses.Approved:
          return !!this.permissionsService.getPermission(PermissionsEnum.ChangeStatusEventsApproved);
        default:
          return true;
      }
    });

    effect(
      () => {
        this.config.columns[changeStatusColumnIndex].show = showChangeStatusColumn();
      },
      {
        injector: this.injector,
      },
    );
  }

  private setRightBarWidth(width: number) {
    const clampedWidth = Math.min(Math.max(width, this.rightBarMinWidth), this.rightBarMaxWidth);

    this.eventsContainer.nativeElement.style.setProperty('--editor-width', `${clampedWidth}px`);
    window.dispatchEvent(new Event('resize'));
  }

  private getRightBarWidth(): number {
    return parseInt(getComputedStyle(this.eventsContainer.nativeElement).getPropertyValue('--editor-width'), 10);
  }
}
