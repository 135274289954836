import { AfterViewInit, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DraggableListViewConfigModel } from '../../shared/draggable-list-view/draggable-list-view-model';
import { getState } from '../../store/models/app.state';
import { changeCurrentProjectEvent, resetCurrentProjectEvent, updateEditor } from '../store/event.actions';
import { ElementTypeEnum } from '../enums/element-type.enum';
import { ListViewColumnModel } from '../../shared/list-view/list-view-column.model';
import { EventGroupModel, EventModel } from '../event.model';
import { FieldType } from '../../shared/draggable-list-view/models/group-model';
import { EventActions } from '../enums/event-actions.enum';
import { EventsEditorTabEnum } from '../events/events.component';
import { ProjectService } from '../../project/project.service';
import { Statuses } from '../../shared/models/statuses.enum';
import { PermissionsEnum } from '../../permission/permissions.enum';
import { EventsListBaseComponent } from '../events-list-base/events-list-base.component';

@Component({
  selector: 'phar-scheduled-events-list',
  templateUrl: './scheduled-events-list.component.html',
  styleUrls: ['./scheduled-events-list.component.scss'],
})
export class ScheduledEventsListComponent extends EventsListBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('eventNameTemplate', { static: true }) eventNameTemplate: TemplateRef<any>;
  @ViewChild('expandIconTemplate', { static: true }) expandIconTemplate: TemplateRef<any>;
  @ViewChild('startTemplate', { static: true }) startTemplate: TemplateRef<any>;
  @ViewChild('beforeDeviationTemplate', { static: true }) beforeDeviationTemplate: TemplateRef<any>;
  @ViewChild('afterDeviationTemplate', { static: true }) afterDeviationTemplate: TemplateRef<any>;
  @ViewChild('elementTypeTemplate', { static: true }) elementTypeTemplate: TemplateRef<any>;
  @ViewChild('eventTypeTemplate', { static: true }) eventTypeTemplate: TemplateRef<any>;
  @ViewChild('isRepeatableTemplate', { static: true }) isRepeatableTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('statusChangeTemplate', { static: true }) statusChangeTemplate: TemplateRef<any>;
  @ViewChild('toggleAllTemplate', { static: true }) toggleAllTemplate: TemplateRef<any>;
  @ViewChild('commentsTemplate', { static: true }) commentsTemplate: TemplateRef<any>;

  editorShown: boolean;
  noEventsMsg = 'Please click Add above on the right to start creating study events.';
  projectService: ProjectService = inject(ProjectService);
  config: DraggableListViewConfigModel = {
    columns: [
      {
        field: 'id',
        size: '0',
        title: 'ID',
        show: false,
        hasTemplate: false,
        template: null,
      },
      {
        field: 'expand',
        size: '40px',
        title: '',
        show: true,
        hasTemplate: true,
        template: null,
      },
      {
        field: 'eventName',
        title: 'Event Name',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'start',
        title: 'Start',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'beforeDeviation',
        title: 'Window Before',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'afterDeviation',
        title: 'Window After',
        size: '1fr',
        show: true,
        order: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'elementType',
        title: 'Event Type',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'isRepeatable',
        title: 'Recurring',
        size: '70px',
        // Add as many pixels as left margin added for nested columns (2rem).
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'userIdUpdated', // @TODO change this to status column when its done
        title: 'Status',
        size: '120px',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'changeStatus',
        title: 'Change status',
        size: '100px',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
        hasHeaderCellTemplate: true,
      },
      {
        field: 'projectEventGuid', // field is used only for placeholder
        title: 'Comments',
        size: '80px',
        show: true,
        hasTemplate: true,
        template: null,
      },
      {
        field: 'userIdCreated', // field is used only for placeholder
        title: 'Actions',
        size: '50px',
        // Add as many pixels as left margin added for nested columns (2rem).
        // sizeNested: '86px',
        show: true,
        hasTemplate: true,
        template: null,
      },
    ],
    actions: [],
    customClasses: ['events-list'],
  };

  eventActionsFnMap = {
    [EventActions.Edit]: this.handleEditEvent,
    [EventActions.Delete]: this.handleDeleteEvent,
    [EventActions.Duplicate]: this.handleDuplicateEvent,
    [EventActions.Comments]: this.openCommentsDialog,
  };

  readonly ElementType = ElementTypeEnum;
  readonly FieldType = FieldType;
  readonly EventsEditorTabEnum = EventsEditorTabEnum;

  constructor() {
    super('scheduled');
  }

  ngAfterViewInit(): void {
    const templates = {
      expand: this.expandIconTemplate,
      eventType: this.eventTypeTemplate,
      elementType: this.elementTypeTemplate,
      afterDeviation: this.afterDeviationTemplate,
      beforeDeviation: this.beforeDeviationTemplate,
      start: this.startTemplate,
      isRepeatable: this.isRepeatableTemplate,
      userIdUpdated: this.statusTemplate,
      changeStatus: this.statusChangeTemplate,
      'changeStatus@header': this.toggleAllTemplate,
      projectEventGuid: this.commentsTemplate,
      userIdCreated: this.actionsTemplate, //userIdCreated is user as a placeholder only
      eventName: this.eventNameTemplate,
    };

    /*if (this.editLocked()) {
      delete templates.userIdCreated;
      this.config.columns = this.config.columns.filter(item => item.field !== 'userIdCreated');
    }*/

    this.config.columns = this.utilsService.setColumnTemplate<ListViewColumnModel>(
      this.config.columns,
      templates,
      'field',
    );
    if (this.selectedEventId) {
      this.scrollToElement(this.selectedEventId);
    }
  }

  actionHandler($event: { eventName: EventActions; dataItem: EventGroupModel }): void {
    const { eventName, dataItem } = $event;
    try {
      this.eventActionsFnMap[eventName].bind(this)(dataItem);
    } catch (e) {
      console.error(`Method not implemented ${eventName}`, e);
    }
  }

  handleEditEvent(dataItem: EventGroupModel): void {
    this.store.dispatch(changeCurrentProjectEvent({ eventId: dataItem.id }));
    this.selectedEventId = dataItem.id;
    this.editorShown = true;
    this.store.dispatch(updateEditor());
  }

  handleAfterUpdate({ update }: { update: boolean }) {
    if (!update) {
      this.addEvent(false);
    }

    this.closeEditor();
  }

  addEvent(reset = true): void {
    if (reset) {
      this.store.dispatch(resetCurrentProjectEvent());
    }
    const selectedEvent = getState(this.store).event.current.projectEvent.event;
    this.editorShown = true;
    this.store.dispatch(updateEditor());
    this.selectedEventId = selectedEvent.id;
    this.scrollToElement(this.selectedEventId);
  }

  selectEvent(event: EventModel): void {
    const projectEvent = this.eventToProjectEventMap()[event.id];

    if (
      this.editLocked() ||
      (this.projectHasBeenRejected() && projectEvent.status === Statuses.Approved) ||
      projectEvent.status === this.nextStatus()
    ) {
      return;
    }

    if (event.elementType === this.ElementType.Baseline || event.id === this.selectedEventId) {
      this.selectedEventId = null;
      this.editorShown = false;
      this.scrollToElement(event.id);
      return;
    }

    this.store.dispatch(changeCurrentProjectEvent({ eventId: event.id }));
    this.selectedEventId = event.id;
    this.editorShown = true;
    this.store.dispatch(updateEditor());
    this.scrollToElement(event.id);
  }

  handleScrollEnd() {
    if (!this.selectedEventId) {
      return;
    }
    this.scrollToElement(this.selectedEventId, true, 'preview-item-');
  }

  scrollToElement(elementId: number, scrollIntoPreviewContainer = false, prefix = 'item-'): void {
    if (!elementId) {
      return;
    }
    // try {
    //   this.utilsService.waitForElementToExist(`#${prefix}${elementId}`).then((element: HTMLElement) => {
    //     if (scrollIntoPreviewContainer) {
    //       const container = document.getElementById('preview-container');
    //       if (!container) {
    //         return;
    //       }
    //       container.scrollLeft = element.offsetLeft - element.clientWidth * 3;
    //       return;
    //     }
    //
    //     (element as HTMLElement).scrollIntoView({ behavior: 'smooth' });
    //   });
    // } catch (e) {
    //   if (!environment.production) {
    //     console.warn(e);
    //   }
    // }
  }

  closeEditor() {
    this.editorShown = false;
    this.selectedEventId = null;
    this.activeEditorTab = EventsEditorTabEnum.Properties;
    this.store.dispatch(resetCurrentProjectEvent());
  }

  closeEditorIfEventOpened(eventId: number): void {
    if (eventId === this.currentEvent().id) {
      this.store.dispatch(resetCurrentProjectEvent());
      this.editorShown = false;
      this.selectedEventId = null;
      this.store.dispatch(updateEditor());
    }
  }

  protected readonly EventActions = EventActions;
  protected readonly Statuses = Statuses;
  protected readonly PermissionsEnum = PermissionsEnum;
}
