/* src/app/events/scheduled-events-list/scheduled-events-list.component.scss */
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-text {
  cursor: text;
}
.cursor-move {
  cursor: move !important;
}
.full-width,
.three-dots-no-wrap {
  width: 100%;
}
.three-dots {
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-dots-no-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.white-space-normal {
  white-space: normal;
}
.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.position-fixed {
  position: fixed;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.full-height {
  height: 100vh;
}
.full-height.fit {
  height: calc(100vh - 50px - 50px);
}
.max-height-100 {
  max-height: 100%;
}
.max-height-50 {
  max-height: 50%;
}
.max-width-100 {
  max-width: 100%;
}
.max-width-50 {
  max-width: 50%;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.max-width {
  max-width: 780px;
}
.background-transparent {
  background: transparent !important;
}
.underline-on-hover:hover {
  text-decoration: underline;
}
.text-underline {
  text-decoration: underline;
}
.no-pointer-events {
  pointer-events: none;
}
.hidden {
  display: none;
}
.h-auto {
  height: auto;
}
.h-100 {
  height: 100%;
}
.t-0 {
  top: 0;
}
.b-0 {
  bottom: 0;
}
.l-0 {
  left: 0;
}
.r-0 {
  right: 0;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-end {
  align-items: flex-end;
}
.flex-grow {
  flex-grow: 1;
}
.inline-block {
  display: inline-block;
}
.small-icon {
  width: 1rem !important;
  min-width: 1rem !important;
  height: 1rem !important;
}
button.reverse-icon {
  display: flex;
  flex-direction: row;
}
button.reverse-icon mat-icon {
  margin: 0 0 0 8px !important;
}
.text-strike-out {
  text-decoration: line-through;
}
:host {
  --editor-width: 325px;
  --list-view-border-radius: 10px;
}
.component-border {
  border-right: 1px solid #babbbd !important;
}
.mat-mdc-slide-toggle.mat-disabled {
  opacity: 1 !important;
}
#preview-scroll-holder {
  overflow-x: auto;
  border: 1px solid #babbbd !important;
  border-radius: var(--list-view-border-radius);
}
.list-view.preview-list.limited-width {
  width: calc(100vw - 600px);
}
.list-view_list_headers {
  display: grid;
  grid-template-rows: auto;
  font-size: 12px;
  font-weight: bold;
  border-radius: var(--list-view-border-radius);
  width: 100%;
}
.list-view_list_headers .recurring-event {
  position: absolute;
  top: 5px;
  right: 5px;
  zoom: 60%;
}
.list-view_list_element {
  display: grid;
  grid-template-rows: auto;
  align-items: baseline;
  padding: 5px;
  opacity: 1;
  font-size: 11px;
  font-weight: bold;
  border-right: 1px solid #babbbd !important;
  min-width: 113px;
  height: 60px;
}
.list-view_list_element:first-child,
.list-view_list_element:last-child {
  border-top-left-radius: var(--list-view-border-radius);
  border-bottom-left-radius: var(--list-view-border-radius);
}
.list-view_list_element:hover,
.list-view_list_element--selected {
  opacity: 1;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.active {
  background-color: #e3e7f2;
}
.baseline {
  background-color: #e2f0e7;
  min-width: 150px !important;
}
.pointer {
  cursor: pointer;
}
::ng-deep .events-list .list-view_list_headers {
  font-size: 10px;
  color: #86878c;
  font-weight: normal !important;
}
.baseline {
  background-color: #e2f0e7;
}
.pointer {
  cursor: pointer;
}
#events-list-container {
  height: calc(100vh - var(--main-header-height) - var(--secondary-header-height) - 270px);
  overflow-y: auto;
}
.scheduled-events-editor {
  width: var(--editor-width);
  display: flex;
  flex-direction: column;
}
.scheduled-events-editor .resize-handle {
  height: 100%;
  width: 2px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: ew-resize;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease-out;
}
.scheduled-events-editor .resize-handle:hover {
  width: 3px;
  background-color: #4f66ab;
}
.scheduled-events-editor .resize-handle:hover::after {
  content: "";
  display: block;
  height: 100%;
  width: 24px;
  position: absolute;
  right: -12px;
  z-index: 1;
}
.scheduled-events-editor .resize-handle.resizing::after {
  width: 100px;
  right: -50px;
}
.event-details {
  display: grid;
  margin-top: 10px;
  grid-template-rows: 1fr 23px;
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=scheduled-events-list.component.css.map */
