import {
  AfterViewInit,
  Component,
  computed,
  effect,
  inject,
  Injector,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  TemplateRef,
  ViewChild,
  WritableSignal,
} from '@angular/core';

import { debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';

import { AssignGroupModel, AssignModel } from '../assign.model';
import { AppState, getState } from '../../store/models/app.state';
import {
  deleteAssign,
  getAssignsByProjectId,
  loadAssignmentsCommentsState,
  resetAssignList,
  softDeleteAssign,
  updateAssignment,
  updateAssignments,
  updateAssignOrder,
  updateCurrentAssign,
} from '../store/assign.actions';
import { CardModel, CardType } from '../../shared/card/card.model';
import { CardService } from '../../shared/card/card.service';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { UtilsService } from '../../core/utils.service';
import { ListViewColumnModel } from '../../shared/list-view/list-view-column.model';
import { DraggableListViewConfigModel } from '../../shared/draggable-list-view/draggable-list-view-model';
import { FieldType } from '../../shared/draggable-list-view/models/group-model';
import { AssignTypeEnum } from '../assign-type.enum';
import { errorPopup, globalLoading, messagePopup } from '../../store/actions/ui.actions';
import { FormModel } from '../../form/form.model';
import { AssignService } from '../assign.service';
import { DraggableListViewComponent } from '../../shared/draggable-list-view/draggable-list-view.component';
import {
  checkProjectHasBeenRejected,
  projectEntitiesLocked,
  selectCurrentProjectManageProject,
  selectCurrentProjectStatusStatus,
} from '../../project/store/project.actions';
import { selectAssignCommentsState, selectAssignListState } from '../store/assign.state';
import { cloneDeep } from 'lodash-es';
import { ListCardView } from '../../shared/card-list-switcher/card-list-switcher.component';
import { BaseComponent } from '../../shared/base.class';
import { ASSIGN_LIST_COLUMN_CONFIG, AssignItemActions, EMPTY_ASSIGNMENT } from './assign-list.configs';
import { PreviewFormQuestionsDialogComponent } from '../../form/preview-form-questions-dialog/preview-form-questions-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ListFilterManager } from '../../shared/list-filter/list-filter-manager.class';
import { IListFilter, IListFilterDisplayOption, ListFilter } from '../../shared/list-filter/list-filter.interface';
import { FilterType } from '../../shared/list-filter/filter-item.interface';
import { AssignCommentsDialogComponent } from '../assign-comments/assign-comments-dialog.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { COMMENTS_DIALOG_CONFIG } from '../../shared/entity-comments/entity-comment-base/base-comments-component.config';
import { EventActions } from '../../events/enums/event-actions.enum';
import { EntityType } from '../../core/models/entity-type-enum';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Statuses } from '../../shared/models/statuses.enum';
import { ICommentsState } from '../../shared/models/comments-state.interface';
import { selectFormStateFormList } from '../../form/store/form.state';
import {
  IReplaceAssessmentDialogData,
  ReplaceAssessmentDialogComponent,
} from '../replace-assessment-dialog/replace-assessment-dialog.component';
import { StudyEntityActionStateService } from '../../shared/services/study-entity-action-state.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissionsEnum } from '../../permission/permissions.enum';

@Component({
  templateUrl: './assign-list.component.html',
  styleUrls: ['./assign-list.component.scss'],
})
export class AssignListComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('createdAtTemplate', { static: true }) createdAtTemplate: TemplateRef<any>;
  @ViewChild('dragIconTemplate', { static: true }) dragIconTemplate: TemplateRef<any>;
  @ViewChild('expandIconTemplate', { static: true }) expandIconTemplate: TemplateRef<any>;
  @ViewChild('assignmentTypeTemplate', { static: true }) assignmentTypeTemplate: TemplateRef<any>;
  @ViewChild('formNameTemplate', { static: true }) formNameTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('versionTemplate', { static: true }) versionTemplate: TemplateRef<any>;
  @ViewChild('commentsTemplate', { static: true }) commentsTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('changeStatusHeaderTemplate', { static: true }) changeStatusHeaderTemplate: TemplateRef<any>;
  @ViewChild('changeStatusTemplate', { static: true }) changeStatusTemplate: TemplateRef<any>;
  @ViewChild('draggableListViewComponent') draggableListViewComponent: DraggableListViewComponent;
  AssignType = AssignTypeEnum;
  groupEditorOpened$: Observable<boolean>;
  FieldType = FieldType;
  list$: Observable<CardModel<AssignModel>[]>;
  injector = inject(Injector);
  store: Store<AppState> = inject(Store);
  lisFilterManager = new ListFilterManager();
  listView: Signal<AssignModel[]> = toSignal(
    this.store.select(selectAssignListState).pipe(
      map(assignList => {
        return assignList.map((assign: AssignModel) => {
          const copy = { ...assign };
          copy['original'] = { ...assign };
          return copy;
        });
      }),
      switchMap((list: AssignModel[]) => {
        return this.lisFilterManager.filters$.pipe(
          map((filters: ListFilter[]) => this.filterAssignments(filters, list)),
        );
      }),
      map((list: AssignModel[]) =>
        list.map(item => {
          const draggingDisabled = computed(() => {
            return (
              item.status === this.nextStatus() || (this.projectHasBeenRejected() && item.status === Statuses.Approved)
            );
          });
          return {
            ...item,
            draggingDisabled,
          };
        }),
      ),
    ),
  );
  utilsService: UtilsService = inject(UtilsService);
  listViewRaw = toSignal(this.store.select(selectAssignListState), {
    injector: this.injector,
  });
  projectId: number;
  header$: Observable<boolean>;
  selectedAssignment: AssignModel;
  view: ListCardView = ListCardView.List;

  isLoading = toSignal(
    this.store.pipe(
      map((state: AppState) => state.ui.globalLoading),
      debounceTime(100),
    ),
    { injector: this.injector },
  );

  editLocked = toSignal(this.store.select(projectEntitiesLocked), {
    injector: this.injector,
  });

  currentProject = toSignal(this.store.select(selectCurrentProjectManageProject), {
    injector: this.injector,
  });

  projectStatus = toSignal(this.store.select(selectCurrentProjectStatusStatus), {
    injector: this.injector,
  });

  projectHasBeenRejected = toSignal(this.store.select(checkProjectHasBeenRejected), {
    injector: this.injector,
  });

  assessmentsContainer = 'assessmentsContainer';
  config: DraggableListViewConfigModel = ASSIGN_LIST_COLUMN_CONFIG;

  idClassMap: WritableSignal<Record<string, string>> = signal({});
  initiallyExpandedItems: WritableSignal<Record<string, boolean>> = signal({});
  commentsState: Signal<Record<number, ICommentsState>> = toSignal(this.store.select(selectAssignCommentsState), {
    injector: this.injector,
  });
  formList: Signal<FormModel[]> = toSignal(this.store.select(selectFormStateFormList), { injector: this.injector });
  allCommentsResolved = computed(() => {
    if (!Object.values(this.commentsState()).length) {
      return true;
    }
    return Object.values(this.commentsState()).every((comments: ICommentsState) => comments.isAllResolved);
  });
  isConfirmAllDisabled = computed(() => {
    return this.studyEntityActionStateService.isBulkStatusChangeLocked(
      this.listViewRaw().map(({ status }) => status),
      this.projectStatus(),
      this.nextStatus(),
    );
  });
  isConfirmAllChecked = computed(() => {
    return this.studyEntityActionStateService.isBulkStatusChangeChecked(
      this.listViewRaw().map(({ status }) => status),
      this.nextStatus(),
    );
  });
  nextStatus = computed(() => {
    return this.utilsService.getNextStatus(this.currentProject());
  });

  prevStatus = computed(() => {
    return this.utilsService.getNextStatus(this.currentProject(), true);
  });

  filterableFields: IListFilterDisplayOption[] = [
    {
      field: 'assignmentType',
      title: 'Assessment Type',
      label: 'Type',
      type: FilterType.Dropdown,
      options: {
        data: of([
          {
            label: 'eClinRO',
            id: this.AssignType.Questionnaire,
          },
          {
            label: 'ePRO',
            id: this.AssignType.DataCapture,
          },
          // {
          //   label: 'eObsRO',
          //   id: this.AssignType.EObsRO,
          // },
          // {
          //   label: 'Informed Consent',
          //   id: this.AssignType.InformedConsent,
          // },
          // {
          //   label: 'Group',
          //   id: this.AssignType.AssignGroupEntity,
          // },
        ]),
      },
    },
  ];
  private readonly _groupEditorOpened$: Subject<boolean> = new Subject<boolean>();
  private readonly emptyAssignment: AssignModel = EMPTY_ASSIGNMENT;

  constructor(
    private assignService: AssignService,
    private confirmationService: PharConfirmDialogService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private studyEntityActionStateService: StudyEntityActionStateService,
    private permissionsService: NgxPermissionsService,
    protected cardService: CardService,
  ) {
    super();
    this.groupEditorOpened$ = this._groupEditorOpened$.asObservable();
  }

  ngOnInit(): void {
    this.initObservables();
    this.initListeners();
    this.checkForHighlightedRow();
    this.handleChangeStatusColumnVisibility();
  }

  ngAfterViewInit(): void {
    this.handleColumnsConfig();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(resetAssignList());
  }

  assignForm(form: FormModel, orderPriority?: number, parentId?: number, reorderData?: any): void {
    const assignment: AssignModel = {
      ...this.emptyAssignment,
      id: 0,
      formId: form.id,
      assignmentType: form.type,
      projectId: this.projectId,
      parentId,
      orderPriority,
      status: this.prevStatus(),
    };

    const relatedAssignment: AssignModel | undefined = this.listViewRaw().find(
      assignment => assignment.formId === form.formId,
    );
    if (relatedAssignment) {
      const isReplaceLocked = this.studyEntityActionStateService.getEntityActionState(
        relatedAssignment.status,
        'replaceLocked',
        this.projectStatus(),
        this.nextStatus(),
      );
      if (isReplaceLocked) {
        this.confirmationService.openConfirmDialog(
          '',
          'Another version of this form has already been added to the study and replace is locked.',
          'Got it.',
          '',
          true,
        );
        return;
      }

      const currentForm = relatedAssignment.form;
      //TODO: compare versionNumber here instead of id ( currentForm.versionNumber < form.versionNumber)
      const isVersionEqual = currentForm.id === form.id;
      const isVersionGreater = currentForm.id < form.id;
      const isVersionLower = currentForm.id > form.id;

      if (isVersionEqual) {
        this.confirmationService.openConfirmDialog(
          '',
          'This form has already been added to the study',
          'Got it',
          '',
          true,
        );
      } else if (isVersionLower) {
        const hasPermission = !!this.permissionsService.getPermission(PermissionsEnum.UndoAssessments);

        if (!hasPermission) {
          this.confirmationService.openConfirmDialog(
            '',
            'You do not have sufficient permissions to replace the form with a lower version',
            'Got it',
            '',
            true,
          );
        } else {
          this.confirmationService.openConfirmDialog(
            '',
            `Higher version of this form has already been added to the study as ${currentForm.name} v${currentForm.version}.`,
            'Got it',
            '',
            true,
          );
        }
      } else if (isVersionGreater) {
        const hasPermission = !!this.permissionsService.getPermission(PermissionsEnum.ReplaceAssessments);

        if (!hasPermission) {
          this.confirmationService.openConfirmDialog(
            '',
            'You do not have sufficient permissions to replace the form with a higher version',
            'Got it',
            '',
            true,
          );
        } else {
          this.confirmationService
            .openConfirmDialog(
              'How do you want to continue?',
              `Another version of this form has already been added to the study as ${currentForm.name} v${currentForm.version}.`,
              'Use this version',
              'Keep other version',
              true,
              450,
            )
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() => {
              this.updateAssignmentToHigherVersion(relatedAssignment, form);
            });
        }
      }

      return;
    }

    const hasPermissionToCreate = !!this.permissionsService.getPermission(PermissionsEnum.CreateAssessments);
    if (!hasPermissionToCreate) {
      this.confirmationService.openConfirmDialog(
        '',
        'You do not have sufficient permissions to create assessment',
        'Got it',
        '',
        true,
      );
      return;
    }

    if (form.formDuplicateId) {
      const duplicatedForm = getState(this.store).form.list.find(f => f.id === form.formDuplicateId);
      let text = '';
      if (duplicatedForm) {
        text = `This form is a copy of <b> ${duplicatedForm.name}</b>. Do you want to continue?`;
      } else {
        text = `This form is a copy and the form parent <b>isn't released</b> already. Do you want to continue?`;
      }
      this.confirmationService
        .openConfirmDialog(text, 'Warning', 'Yes', 'No')
        .pipe(filter(result => !!result))
        .subscribe({
          next: () => {
            this.createUpdateAssignment(assignment, false, reorderData);
          },
        });
      return;
    }

    this.createUpdateAssignment(assignment, false, reorderData);
  }

  addGroup(): void {
    this.selectedAssignment = null;
    this._groupEditorOpened$.next(true);
  }

  openGroupEditor(): void {
    this._groupEditorOpened$.next(true);
  }

  closeGroupEditor(): void {
    this._groupEditorOpened$.next(false);
    this.selectedAssignment = null;
  }

  saveGroup(groupName: string): void {
    const isUpdate = !!this.selectedAssignment?.id;

    const assignment: AssignModel = isUpdate
      ? {
          ...this.selectedAssignment,
          groupName,
        }
      : {
          ...this.emptyAssignment,
          id: 0,
          groupName,
          projectId: this.projectId,
          assignmentType: AssignTypeEnum.AssignGroupEntity,
        };

    this.createUpdateAssignment(assignment, isUpdate);
    this._groupEditorOpened$.next(false);
  }

  actionHandler($event: { eventName: string; dataItem: AssignGroupModel }): void {
    switch ($event.eventName) {
      case AssignItemActions.Delete:
        $event.dataItem.type === FieldType.Group
          ? this.openDeleteGroupAssignment($event)
          : this.openDeleteSingleAssignment($event);
        break;
      case AssignItemActions.Edit:
        if ($event.dataItem.type === FieldType.Group) {
          this.store.dispatch(updateCurrentAssign({ assign: $event.dataItem as unknown as AssignModel }));
          this.selectedAssignment = $event.dataItem as unknown as AssignModel;
          this.openGroupEditor();
        }
        break;

      // case AssignItemActions.Duplicate:
      //   this.duplicateActionHandler($event.dataItem);
      //   break;
      case AssignItemActions.Comments:
        this.openAssignmentComments($event.dataItem as unknown as AssignModel);
        break;
      case AssignItemActions.Preview:
        this.dialog.open(PreviewFormQuestionsDialogComponent, {
          width: '75%',
          maxWidth: '880px',
          height: '90%',
          data: {
            form: { id: $event.dataItem.form.id },
          },
        });
        break;
      case AssignItemActions.Replace:
        this.openReplaceDialog($event.dataItem as AssignModel);
        break;
    }
  }

  openAssignmentComments(assignment: AssignModel): void {
    this.dialog
      .open(AssignCommentsDialogComponent, {
        data: {
          assignmentId: assignment.id,
        },
        ...COMMENTS_DIALOG_CONFIG,
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(shouldUpdateCounters => shouldUpdateCounters),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(loadAssignmentsCommentsState({ projectId: this.projectId }));
          if (assignment.status === this.nextStatus()) {
            // if the entity is already in next status, we need to update it with prev status because some comments has been added
            // keep in mind that if project has been rejected before that, the prev status will be PendingRevision
            /*this.store.dispatch(
              updateAssignment({
                assignment: {
                  ...assignment,
                  status: this.prevStatus(),
                  permissionsIds: [],
                },
              }),
            );*/
          }
        },
      });
  }

  openReplaceDialog(assignment: AssignModel): void {
    const relatedForms = this.formList().filter(
      form => form.formId === assignment.formId && form.id !== assignment.form.id,
    );
    //TODO: compare versionNumber here instead of id ( form.versionNumber > assignment.form.id)
    const formsWithHigherVersion = relatedForms.filter(form => form.id > assignment.form.id);

    this.dialog
      .open<ReplaceAssessmentDialogComponent, IReplaceAssessmentDialogData>(ReplaceAssessmentDialogComponent, {
        width: '400px',
        disableClose: true,
        data: {
          forms: formsWithHigherVersion,
        },
      })
      .afterClosed()
      .pipe(
        take(1),
        filter((form): form is FormModel => !!form),
      )
      .subscribe(form => {
        this.updateAssignmentToHigherVersion(assignment, form);
      });
  }

  onReorder({ reorderData }: { reorderData: AssignModel[] }): void {
    this.store.dispatch(
      updateAssignOrder({
        items: reorderData,
      }),
    );
  }

  handleElementAdded(data: { item: any; index: number; parentId: number | undefined; reorderData: any[] }): void {
    this.assignForm(data.item, data.index, data.parentId, data.reorderData);
  }

  handleFilterChange(data: { filter: IListFilter; mainFilter: boolean }): void {
    this.lisFilterManager.createFilter(data);
  }

  handleFilterUpdate(data: { action: 'update' | 'delete'; filter: ListFilter | ListFilter[] }): void {
    if (data.action === 'update') {
      this.lisFilterManager.updateFilter(data.filter);
    } else {
      this.lisFilterManager.removeFilter(data.filter);
    }
  }

  itemStatusChange(assignment: AssignModel, el: MatSlideToggle): void {
    if (!el.checked) {
      el.checked = true;
      return;
    }
    const itemMeta: ICommentsState = this.commentsState()[assignment.id];
    if (itemMeta && !itemMeta.isAllResolved) {
      this.confirmationService
        .openConfirmDialog('All comments should be resolved', 'Warning', 'Ok', '')
        .pipe(take(1))
        .subscribe();
      el.checked = false;
      return;
    }

    if (assignment.status === Statuses.PendingDeletion) {
      this.confirmationService
        .openConfirmDialog('Do you want to remove ' + assignment.formName + '?')
        .pipe(
          take(1),
          filter(isConfirmed => isConfirmed),
        )
        .subscribe(() => {
          this.softDeleteAssignment(assignment.id);
        });
    } else {
      this.store.dispatch(
        updateAssignment({
          assignment: {
            ...assignment,
            status: this.nextStatus(),
            permissionsIds: [],
          },
        }),
      );
    }
  }

  handleBulkStatusChange(el: MatSlideToggle): void {
    if (!el.checked) {
      el.checked = true;
      return;
    }

    if (!this.allCommentsResolved()) {
      this.confirmationService
        .openConfirmDialog('All assignment comments should be resolved', 'Warning', 'Ok', '')
        .pipe(take(1))
        .subscribe({});
      el.checked = false;
      return;
    }

    const assignments: AssignModel[] = this.listViewRaw().filter(
      ({ status }) => !this.studyEntityActionStateService.isEntityChangeStatusChecked(status, this.nextStatus()),
    );
    this.confirmationService
      .openConfirmDialog(
        `You are about to move <b>${assignments.length}</b> records in status <b>${this.utilsService.getFormattedStatus(this.nextStatus())}</b>. Are you sure?`,
        'Please confirm',
        'Ok',
        'Cancel',
        true,
        300,
        true,
      )
      .pipe(
        take(1),
        map(result => !!result),
        tap(result => {
          if (!result) {
            el.checked = false;
          }
        }),
        filter(canChangeStatuses => canChangeStatuses),
      )
      .subscribe({
        next: () => {
          //TODO: delete pending deletion assignments. Confirm button is disabled if there are pending deletion now

          const updatedAssignments = assignments.map(projectEvent => ({
            ...projectEvent,
            status: this.nextStatus(),
            permissionsIds: [],
          }));
          this.store.dispatch(updateAssignments({ assignments: updatedAssignments }));
        },
      });
  }

  private createUpdateAssignment(
    assignment: AssignModel,
    isUpdate = false,
    reorderData?: AssignModel[],
  ): Observable<AssignModel> {
    this.store.dispatch(globalLoading(true));
    const errorMsg = isUpdate ? 'Assignment update failed' : 'Assignment failed';
    const successMsg = isUpdate ? 'Assignment update success' : 'Assignment success';
    const createUpdateResponse$ = new Subject<AssignModel | null>();

    this.store
      .select(selectAssignListState)
      .pipe(
        take(1),
        map(list => list.filter(item => !item.parentId).length),
        map(orderPriority => {
          return typeof assignment.orderPriority === 'number' && !Number.isNaN(assignment.orderPriority)
            ? assignment
            : {
                ...assignment,
                orderPriority,
              };
        }),
        switchMap(assignment => {
          if (isUpdate) {
            return this.assignService.updateAssignments([assignment]);
          }

          return this.assignService.createAssignment(assignment);
        }),
        take(1),
      )
      .subscribe({
        next: (res: AssignModel) => {
          createUpdateResponse$.next(res);
          // in case we add element to a certain order we need to reorder all other elements
          if (reorderData) {
            this.onReorder({ reorderData });
            // this will invoke the getAssignsByProjectId when the reorder is done
          } else {
            this.store.dispatch(
              getAssignsByProjectId({
                projectId: this.projectId,
              }),
            );
          }
          this.store.dispatch(messagePopup({ message: successMsg }));
        },
        error: () => {
          this.store.dispatch(errorPopup({ error: errorMsg }));
          this.store.dispatch(globalLoading(false));
          createUpdateResponse$.next(null);
        },
      });

    return createUpdateResponse$;
  }

  private openDeleteSingleAssignment($event: { eventName: string; dataItem: AssignGroupModel }) {
    this.confirmationService
      .openConfirmDialog('Do you want to remove ' + $event.dataItem.formName + '?')
      .pipe(
        take(1),
        filter(isConfirmed => isConfirmed),
      )
      .subscribe(() => {
        //now delete button is disabled if assessment status is 'replaced', because this condition is not handled here.
        //probably we will need to enable it and to change to a previous version that was before replacement if user clicked to delete it.
        //checking project status to be RevisionInProgress is not needed here, because deleting is enabled only
        //for draft and revision in progress statuses and in draft status we can not have items with Active status.
        if (this.projectStatus() === Statuses.RevisionInProgress && $event.dataItem.status === Statuses.Active) {
          const updatedAssignment = {
            ...($event.dataItem as AssignModel),
            status: Statuses.PendingDeletion,
          };

          this.createUpdateAssignment(updatedAssignment, true);
        } else {
          this.deleteAssignment($event.dataItem.id);
        }
      });
  }

  private softDeleteAssignment(id: number): void {
    this.store.dispatch(softDeleteAssign({ id: id, deleteGroup: true }));
    this.afterDeleteHandler(id);
  }

  private deleteAssignment(id: number): void {
    this.store.dispatch(deleteAssign({ id: id, deleteGroup: true }));
    this.afterDeleteHandler(id);
  }

  private openDeleteGroupAssignment($event: { eventName: string; dataItem: AssignGroupModel }) {
    this.confirmationService
      .openConfirmDialog(
        `Would you like to delete the group and all related assessments or to ungroup the assessments?` +
          $event.dataItem.formName +
          '?',
        '',
        'Delete All',
        'Ungroup',
      )
      .pipe(
        take(1),
        filter(result => result !== undefined), // undefined act as cancel
      )
      .subscribe(result => {
        const deleteGroupWithChildAssesments = !!result;
        this.store.dispatch(
          deleteAssign({
            id: $event.dataItem.id,
            deleteGroup: deleteGroupWithChildAssesments,
          }),
        );
        this.afterDeleteHandler($event.dataItem.id);
      });
  }

  private afterDeleteHandler(deleteId: number): void {
    if (this.selectedAssignment && this.selectedAssignment.id === deleteId) {
      this.closeGroupEditor();
    }
  }

  private generateNewGroupName(group: AssignModel): string {
    let newGroupName = group.groupName + ' (copy)';
    const list: AssignModel[] = getState(this.store).assign.list;

    while (
      list
        .filter(item => !!item.groupName)
        .some(item => item.groupName.toLowerCase().trim() === newGroupName.toLowerCase().trim())
    ) {
      newGroupName = newGroupName + ' (copy)';
    }
    return newGroupName;
  }

  private updateAssignmentToHigherVersion(assignment: AssignModel, form: FormModel): void {
    const status: Statuses = (() => {
      if (this.projectStatus() === Statuses.Draft) {
        return this.prevStatus();
      }

      if (assignment.status === Statuses.New) {
        return Statuses.New;
      }

      return Statuses.Replaced;
    })();

    const updatedAssignment = {
      ...assignment,
      form: form,
      formId: form.id,
      status,
    };

    this.createUpdateAssignment(updatedAssignment, true);
  }

  // private duplicateActionHandler(originalAssign: AssignGroupModel): void {
  //   const isGroup = originalAssign.type === FieldType.Group;
  //   const orderPriority = originalAssign.orderPriority + 1;
  //   const newAssignment: AssignModel = isGroup
  //     ? {
  //       ...originalAssign.original,
  //       id: 0,
  //       orderPriority,
  //       groupName: this.generateNewGroupName(originalAssign.original),
  //     }
  //     : {
  //       ...originalAssign.original,
  //       id: 0,
  //       orderPriority,
  //     };
  //
  //
  //   this.store.select(selectAssignListState)
  //     .pipe(
  //       map((list) => {
  //         if (originalAssign.parentId) {
  //           return list.filter(assign => assign.parentId === originalAssign.parentId);
  //         }
  //
  //         return list.filter(assign => !assign.parentId);
  //       }),
  //       map((list) => list.map((assign) => {
  //         if (assign.orderPriority >= orderPriority) {
  //           return {
  //             ...assign,
  //             orderPriority: assign.orderPriority + 1,
  //           };
  //         }
  //
  //         return assign;
  //       })),
  //       switchMap((reorderData) => this.createUpdateAssignment(newAssignment, false, reorderData)),
  //       take(1),
  //     )
  //     .subscribe((duplicatedAssignment) => {
  //       if (isGroup && duplicatedAssignment) {
  //         this.duplicateGroupChildren(originalAssign.id, duplicatedAssignment.id);
  //       }
  //     });
  // }

  // private duplicateGroupChildren(groupId: number, newGroupId: number): void {
  //   this.store.select(selectAssignListState)
  //     .pipe(
  //       take(1),
  //       map((list: AssignModel[]) => {
  //         return list.filter((assignment) => assignment.parentId === groupId);
  //       }),
  //       map((groupChildren: AssignModel[]) => {
  //         return sortBy(groupChildren, (assignment) => assignment.orderPriority)
  //           .map((assignment, index) => ({
  //             ...assignment,
  //             parentId: newGroupId,
  //             orderPriority: index,
  //           }));
  //       }),
  //       filter((newGroupChildren: AssignModel[]) => !!newGroupChildren.length),
  //       switchMap((newGroupChildren: AssignModel[]) => {
  //         return this.assignService.createAssignments(newGroupChildren);
  //       }),
  //       take(1),
  //     )
  //     .subscribe({
  //       next: () => {
  //         this.store.dispatch(getAssignsByProjectId({ projectId: this.projectId }));
  //       },
  //       error: () => {
  //         this.store.dispatch(errorPopup({ error: 'Failed to duplicate elements in group' }));
  //       },
  //     });
  // }

  private initListeners(): void {
    this.store
      .pipe(
        select(state => state.project.current.project.id),
        filter(id => !!id),
        take(1),
      )
      .subscribe(id => {
        this.projectId = id;
        this.store.dispatch(getAssignsByProjectId({ projectId: id }));
        // this.store.dispatch(getGroups());
      });
  }

  private initObservables(): void {
    this.header$ = this.store.select(state => state.ui.header);
    this.list$ = this.store.pipe(
      select(state => state.assign.list),
      map(assigns => {
        return assigns.map(a => ({
          card: {
            settings: this.cardService.mapCardSettings(a?.settings?.cardSettings),
            date: new Date(a.createdAt),
            type: CardType.Assignment,
            name: a.formName,
          },
          original: a,
        }));
      }),
    );
  }

  private handleColumnsConfig(): void {
    const templates = {
      createdAt: this.createdAtTemplate,
      drag: this.dragIconTemplate,
      expand: this.expandIconTemplate,
      assignmentType: this.assignmentTypeTemplate,
      formName: this.formNameTemplate,
      updatedAt: this.actionsTemplate,
      form: this.versionTemplate,
      userIdUpdated: this.commentsTemplate,
      status: this.statusTemplate,
      'changeStatus@header': this.changeStatusHeaderTemplate, //@TODO change the key to be status field
      changeStatus: this.changeStatusTemplate,
    };
    this.config.columns = this.utilsService.setColumnTemplate<ListViewColumnModel>(
      this.config.columns,
      templates,
      'field',
    );
  }

  private handleChangeStatusColumnVisibility(): void {
    const changeStatusColumnIndex = this.config.columns.findIndex(c => c.field === 'changeStatus');
    if (changeStatusColumnIndex < 0) {
      return;
    }
    const showChangeStatusColumn = computed(() => {
      switch (this.nextStatus()) {
        case Statuses.ReadyForReview:
          return !!this.permissionsService.getPermission(PermissionsEnum.ChangeStatusAssessmentsReadyForReview);
        case Statuses.Approved:
          return !!this.permissionsService.getPermission(PermissionsEnum.ChangeStatusAssessmentsApproved);
        default:
          return true;
      }
    });

    effect(
      () => {
        this.config.columns[changeStatusColumnIndex].show = showChangeStatusColumn();
      },
      {
        injector: this.injector,
      },
    );
  }

  private filterAssignments(filters: ListFilter[], list: AssignModel[]): AssignModel[] {
    if (!filters.length) {
      return list;
    }
    let filtered = cloneDeep(list);
    for (const filter of filters) {
      filtered = this.filterByField(filter, filtered);
    }
    return filtered;
  }

  private filterByField(filter: ListFilter, list: AssignModel[]): AssignModel[] {
    let filtered: AssignModel[] = [];
    const { value } = filter;
    if (value === null || value === undefined || value === '') {
      return list;
    }
    filtered = list.filter(item => {
      const { formName, groupName, assignmentType } = item;
      if (filter.field === 'formName') {
        const fieldToCheck = formName || groupName || '';
        const val = this.utilsService.trimToLower(value);
        return this.utilsService.trimToLower(fieldToCheck).includes(val);
      } else {
        return assignmentType === value;
      }
    });

    const parents: AssignModel[] = [];
    let children: AssignModel[] = [];
    filtered.forEach((item, index, arr: AssignModel[]) => {
      // if item has a parent and the parent is not presented in the filteredArr
      if (
        item.parentId &&
        !arr.find(arrItem => arrItem.id === arrItem.parentId) &&
        !parents.find(arrItem => arrItem.id === item.parentId)
      ) {
        parents.push(list.find(arrItem => arrItem.id === item.parentId));
      }
      // if its group, children should be there as well
      if (!item.parentId && !item.formId) {
        const itemChildren = list.filter(
          assign => assign.parentId === item.id && !arr.find(child => child.id === assign.id),
        );
        children = [...children, ...itemChildren];
      }
    });
    return [...filtered, ...parents, ...children];
  }

  private checkForHighlightedRow(): void {
    const id = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (!id) {
      return;
    }

    const highlightEffect = effect(
      () => {
        const assignment: AssignModel = this.listViewRaw().find(x => x.id === Number(id));
        if (!assignment) {
          return;
        }
        this.idClassMap.set({
          [id]: 'highlighted',
        });
        if (assignment.parentId) {
          //expand parent group
          this.initiallyExpandedItems.set({
            [assignment.parentId]: true,
          });
        }

        highlightEffect.destroy();
      },
      {
        manualCleanup: true,
        injector: this.injector,
        allowSignalWrites: true,
      },
    );
  }

  // changeView($event: ListCardView): void {
  //   this.view = $event;
  // }
  // goToCreateNew(): void {
  // const dialogRef = this.dialog.open(AssignFormsComponent, {
  //   width: '980px',
  //   minWidth: 980,
  //   backdropClass: 'assign-form_backdrop',
  //   panelClass: 'assign-form-dialog',
  //   data: {
  //     mode: 'add'
  //   },
  //   autoFocus: false,
  //   restoreFocus: false
  // });
  // dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
  // });
  // }

  // clickHandler($event: { dataItem: GroupModel }): void {
  //   this.store.dispatch(getAssignById({ id: $event.dataItem.id }));
  //   const dialogRef = this.dialog.open(AssignFormsComponent, {
  //     width: '980px',
  //     minWidth: 980,
  //     backdropClass: 'assign-form_backdrop',
  //     panelClass: 'assign-form-dialog',
  //     data: {
  //       mode: 'edit'
  //     },
  //     autoFocus: false,
  //     restoreFocus: false
  //   });
  //   dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
  //   });
  // }
  protected readonly AssignItemActions = AssignItemActions;
  protected readonly EventActions = EventActions;
  protected readonly EntityType = EntityType;
  protected readonly Statuses = Statuses;
  protected readonly PermissionsEnum = PermissionsEnum;
}
