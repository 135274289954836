import {
  Component,
  computed,
  effect,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  Signal,
  signal,
} from '@angular/core';
import { ProjectModel } from '../../project.model';
import { Statuses } from '../../../shared/models/statuses.enum';
import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { IApprovalStep } from '../../../shared/approval-step/approval-step.interface';
import { ApprovalStepComponent } from '../../../shared/approval-step/approval-step.component';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../../../shared/phar-button/phar-button.component';
import { IProjectStatus } from '../../project-status.interface';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PermissionsEnum } from '../../../permission/permissions.enum';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'phar-study-approval-steps',
  templateUrl: 'study-approval-steps.component.html',
  styleUrls: ['study-approval-steps.component.scss'],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    ApprovalStepComponent,
    DatePipe,
    MatIcon,
    ButtonComponent,
    NgxPermissionsModule,
    MatProgressSpinner,
  ],
})
export class StudyApprovalStepsComponent {
  study: InputSignal<ProjectModel> = input.required<ProjectModel>();
  loading = input<boolean>();
  statusHistory: InputSignal<
    Partial<Record<Statuses, { all: IProjectStatus[]; last: IApprovalStep | null }> & { history: IApprovalStep[] }>
  > = input.required<Partial<Record<Statuses, { all: IProjectStatus[]; last: IApprovalStep | null }>>>();

  requestStatusChange: OutputEmitterRef<{ nextStatus: Statuses; step?: IApprovalStep }> = output<{
    nextStatus: Statuses;
    step?: IApprovalStep;
  }>();
  STATUSES_WITHOUT_RELEASE_STEP = [
    Statuses.Published,
    Statuses.RevisionInProgress,
    Statuses.RevisionRejected,
    Statuses.RevisionCompleted,
  ];
  currentStatus: Signal<IProjectStatus> = computed(() => {
    return this.study().status;
  });
  REJECTED_STATUS: IApprovalStep = {
    status: Statuses.Rejected,
    label: 'Rejected',
    done: false,
    active: true,
    icon: 'close',
    successClass: 'rejected',
    version: 0,
  };
  RELEASED_STATUS: IApprovalStep = {
    status: Statuses.Released,
    label: 'Released',
    done: false,
    active: true,
    icon: 'check',
    version: 0,
  };

  statusMap: Partial<Record<Statuses, IApprovalStep>> = {
    [Statuses.Draft]: { status: Statuses.Draft, label: 'Draft', done: false, active: false, version: 0 },
    [Statuses.ReadyForReview]: {
      status: Statuses.ReadyForReview,
      label: 'Sent for review',
      done: false,
      active: false,
      version: 0,
    },
    [Statuses.UnderReview]: {
      status: Statuses.UnderReview,
      label: 'Under Review',
      done: false,
      active: false,
      version: 0,
    },
    [Statuses.Released]: { status: Statuses.Released, label: 'Released', done: false, active: false, version: 0 },
  };

  steps = signal<IApprovalStep[]>([]);

  constructor() {
    effect(
      () => {
        if (this.currentStatus()) {
          this.steps.set(this.generateSteps(this.currentStatus()));
        }
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  private generateSteps(projectStatus: IProjectStatus): IApprovalStep[] {
    let statusMapClone = { ...this.statusMap };
    const { status } = projectStatus;
    if (status === Statuses.Rejected) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete statusMapClone[Statuses.Released];

      statusMapClone = {
        ...statusMapClone,
        [Statuses.Rejected]: this.REJECTED_STATUS,
      };
    }
    const currentStatusIndex = Object.keys(statusMapClone).indexOf(status);
    let currentStep: IApprovalStep = statusMapClone[status];

    if (!currentStep) {
      // if the step is not in the status map we should mark all as done
      Object.values(statusMapClone).forEach(step => {
        step.done = true;
        step.active = false;
      });

      if (this.STATUSES_WITHOUT_RELEASE_STEP.includes(status)) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete statusMapClone[Statuses.Released];
      }

      return Object.values(statusMapClone);
    }

    currentStep = this.mapAdditionalFields(currentStep, projectStatus);

    const forceDone = currentStep.status === Statuses.Rejected || currentStep.status === Statuses.Released;
    Object.values(statusMapClone).forEach((step, index) => {
      step.done = index < currentStatusIndex || forceDone;
      step.active = index === currentStatusIndex;
    });

    if (currentStep.status === Statuses.Released) {
      // if status is released, we should show the release history
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete statusMapClone[Statuses.Released];
    }

    return Object.values(statusMapClone);
  }

  private mapAdditionalFields(step: IApprovalStep, projectStatus: IProjectStatus): IApprovalStep {
    const { createdAt } = projectStatus;
    const clone = { ...step };
    if (createdAt) {
      clone.updatedAt = createdAt;
    }

    return clone;
  }

  protected readonly Statuses = Statuses;
  protected readonly PermissionsEnum = PermissionsEnum;
}
